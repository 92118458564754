.container {
  padding: 20px;
  max-width: 1350px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.create-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.info-container {
  max-width: 650px;
}

a {
  text-decoration: none;
  color: white;
}

.menu {
  padding: 10px;
  display: flex;
  flex-direction: row !important;
  box-shadow: none !important;
}

.messagesWrapper {
  overflow-y: auto !important;
  word-wrap: break-word !important;
  height: 60% !important;
}

@media only screen and (max-width: 1050px) {
  .create-container {
    flex-direction: column;
    align-items: center;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #c2e0ff inset !important;
}
